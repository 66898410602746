<template>
  <div>
    <iframe width="100%" height="650px"
      src="https://datastudio.google.com/embed/reporting/b1fed4d9-bd5c-4d0a-9304-22c6728c7ee1/page/zgsCD"
      frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
}
</script>
